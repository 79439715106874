import './App.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [LoggedIn, setLoggedIn] = useState(false);

  const [mainpage, setMainPage] = useState('Public');

  const [menupage, setMenuPage] = useState('Login');

  const [isid, setIsId] = useState('');
  const [ispsw, setIsPsw] = useState('');
  const [isname, setIsName] = useState('');
  const [isnumber, setIsNumber] = useState('');
  const [iscarnum, setIsCarNum] = useState('');

  const [userData, setUserData] = useState('');
  const [parkingData, setParkingData] = useState([]);
  const [results, setResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState({ carnum: '', location: '', hours: '' });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8; // 페이지당 아이템 수

  const [editingIndex, setEditingIndex] = useState(null); // 수정 모드인 차량의 인덱스
  const [editedCarData, setEditedCarData] = useState({ carnum: '', location: '', hours: '' }); // 수정된 값

  useEffect(() => {
    loginCheck();
  }, []);

  const loginCheck = async () => {
    try {
      const response = await axios.get('https://6capstone2024.kro.kr/api/loginCheck', { withCredentials: true });
      if (response.data.isLoggedIn) {
        setLoggedIn(true);
        // 관리자 로그인 일때
        if (response.data.username === 'admin') {
          setMenuPage('AdminSearch');
          fetchData();
          setMainPage('Admin');
        } else { // 관리자 로그인이 아닐때
          setMenuPage('UserSearch');
          userfetchData(response.data.username); // username을 인자로 넘김
          setMainPage('User');
        }
      } else {
        setLoggedIn(false);
      }
    } catch (error) {
      console.error('세션 확인 오류:', error);
    }
  };

  const handleAuthorAdd = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://6capstone2024.kro.kr/api/AuthorAdd', {
        username: isid,
        password: ispsw,
        realname: isname,
        number: isnumber,
        carnum: iscarnum
      }, { withCredentials: true });
      if (response.data.success) {
        alert('등록되었습니다.');
        setMenuPage('Login');
      } else {
        alert(response.data.message); // 일반 오류 메시지
      }
    } catch (error) {
      console.error('서버 에러:', error);
      alert('서버에 문제가 발생했습니다.');
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://6capstone2024.kro.kr/api/login', {
        username: isid,
        password: ispsw,
      }, { withCredentials: true });
      if (response.data.success) {
        alert('로그인 성공!');
        window.location.reload()
      } else {
        alert('로그인 실패: ' + response.data.message);
      }
    } catch (error) {
      console.error('서버 에러:', error);
      alert('서버에 문제가 발생했습니다.' + error);
    }
  };

  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://6capstone2024.kro.kr/api/logout', {}, { withCredentials: true });
      if (response.data.success) {
        alert('로그아웃 성공!');
        setMenuPage('Login')
        window.location.reload()
      } else {
        alert('로그아웃 실패: ' + response.data.message);
      }
    } catch (error) {
      console.error('서버 에러:', error);
      alert('서버에 문제가 발생했습니다.');
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('https://6capstone2024.kro.kr/api/data'); // 전체 데이터를 요청
      setParkingData(response.data);
      setMainPage('Admin');
    } catch (error) {
      console.error('데이터 가져오기 실패:', error);
    }
  };

  const userfetchData = async (username) => {
    try {
      const response = await axios.get('https://6capstone2024.kro.kr/api/userdata', {
        params: { username }, // 인자로 받은 username을 사용
        withCredentials: true,
      });
      setParkingData(response.data.testResults);
      setUserData(response.data.realname)
      setMainPage('User');
    } catch (error) {
      console.error('데이터 가져오기 실패:', error);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get('https://6capstone2024.kro.kr/api/data', { params: searchTerm });
      setMainPage('Search');
      setResults(response.data);
      setCurrentPage(1); // 검색 시 첫 페이지로 초기화
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleUserSearch = async (e) => {
    e.preventDefault();
    // 만약 parkingData가 비어있지 않다면, 첫 번째 차량의 carnum을 searchTerm.carnum으로 설정
    if (parkingData.length > 0) {
      const carnumToSearch = parkingData[0].carnum; // 첫 번째 차량의 번호를 가져옴
      setSearchTerm((prev) => ({ ...prev, carnum: carnumToSearch })); // searchTerm 업데이트
    }
    try {
      const response = await axios.get('https://6capstone2024.kro.kr/api/usersearch', { params: searchTerm });
      setMainPage('Search');
      setResults(response.data);
      setCurrentPage(1); // 검색 시 첫 페이지로 초기화
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchTerm((prev) => ({ ...prev, [name]: value })); // 입력 필드 변경 시 상태 업데이트
  };

  const goToMainPage = () => {
    setMainPage('Admin'); // 메인 페이지로 설정
    setCurrentPage(1); // 메인 페이지로 돌아갈 때 첫 페이지로 초기화
  };

  const goToMainPageUser = () => {
    setMainPage('User'); // 메인 페이지로 설정
    setCurrentPage(1); // 메인 페이지로 돌아갈 때 첫 페이지로 초기화
  };


  // 현재 페이지에 표시할 데이터 계산
  const indexOfLastCar = currentPage * itemsPerPage;
  const indexOfFirstCar = indexOfLastCar - itemsPerPage;
  const currentCars = parkingData.slice(indexOfFirstCar, indexOfLastCar);

  const totalPages = Math.ceil(parkingData.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleEditClick = (index, car) => {
    setEditingIndex(index);
    setEditedCarData({
      carnum: car.carnum,
      location: car.location,
      hours: car.hours,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCarData({
      ...editedCarData,
      [name]: value,
    });
  };

  const handleSaveClick = async (originalCarnum) => {
    try {
      await axios.post('https://6capstone2024.kro.kr/api/EditSave', {
        originalCarnum, // 기존의 차량 넘버
        newCarnum: editedCarData.carnum, // 수정된 차량 넘버
        location: editedCarData.location,
        hours: editedCarData.hours,
      });
      alert('저장이 완료되었습니다.');
      setEditingIndex(null); // 수정 모드 해제
    } catch (error) {
      console.error('저장 중 오류 발생:', error);
      alert('저장에 실패했습니다.');
    } finally {
      window.location.reload()
    }
  };

  const handleDeleteClick = async (carnum, location, hours) => {
    try {
      await axios.delete('https://6capstone2024.kro.kr/api/DeleteCar', {
        data: { carnum, location, hours } // 삭제할 데이터
      });
      alert('삭제가 완료되었습니다.');
    } catch (error) {
      console.error('삭제 중 오류 발생:', error);
      alert('삭제에 실패했습니다.');
    } finally {
      window.location.reload()
    }
  };

  const [MapModal, setMapModal] = useState(false);
  const [position, setposition] = useState([]);

  const handleLocationValues = (location) => {
    if (location && typeof location === 'string') {

      const locationParts = location.split(',');

      if (locationParts.length === 2) {
        const latitude = parseFloat(locationParts[0].split(':')[1]?.trim()); // '위도:45' -> 45
        const longitude = parseFloat(locationParts[1].split(':')[1]?.trim()); // '경도:45' -> 45

        if (!isNaN(latitude) && !isNaN(longitude)) {
          setposition([latitude, longitude]);
          setMapModal(true);
        } else {
          alert("위도 또는 경도가 유효하지 않습니다.");
        }
      } else {
        alert("위치 형식이 올바르지 않습니다.");
      }
    } else {
      alert("위치 정보가 없거나 잘못된 형식입니다.");
    }
  };

  return (
    <div className='container'>
      {MapModal && position && (
        <div className='mapmodalbox'>
          <div className='mapmodal'>
            <MapContainer center={position} zoom={13} style={{ width: "100%", height: "100%" }}>
              {/* 지도 타일 설정 */}
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {/* 마커 추가 */}
              <Marker
                position={position}
                icon={new L.Icon({
                  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
                  iconSize: [25, 41], // 아이콘 크기
                  iconAnchor: [12, 41], // 아이콘의 앵커 위치
                  popupAnchor: [1, -34], // 팝업의 앵커 위치
                  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
                  shadowSize: [41, 41], // 그림자 크기
                })}
              >
              </Marker>
            </MapContainer>
            <button onClick={()=>setMapModal(false)}>닫기</button>
          </div>
        </div>
      )}
      <div className='header'>
        <div className='logobox'>
          <div class="line"></div>
          <div>
            <h1 style={{ 'margin': '0px' }}>A Parking Violation</h1>
            <h3 style={{ 'margin': '0px' }}>Search & List Deployment page</h3>
            <h4 style={{ 'margin': '0px' }}>한국 불법 주정차 검색 & 리스트 목록</h4>
          </div>
        </div>
      </div>
      <div className='side'>
        <div className='menubox'>
          {menupage === 'Login' ? (
            <>
              <div className='form'>
                <h4>로그인</h4>
                <input type='text' name='username' placeholder='아이디' onChange={event => setIsId(event.target.value)}></input>
                <input type='password' name='psw' placeholder='비밀번호' onChange={event => setIsPsw(event.target.value)}></input>
                <p>
                  <input type='submit' value='회원가입' onClick={() => setMenuPage('Author')}></input>
                  <input type='submit' value='로그인' onClick={handleLogin}></input>
                </p>
              </div>
            </>
          ) : menupage === 'AdminSearch' ? (
            <div className='pagination'>
              <h3>관리자 페이지</h3>
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                이전
              </button>
              <span> 페이지 {currentPage} / {totalPages} </span>
              <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                다음
              </button>
              <p></p>
              <input
                type='text'
                name='carnum'
                placeholder='차량 번호 입력'
                value={searchTerm.carnum}
                onChange={handleChange}
              />
              <input
                type='text'
                name='location'
                placeholder='위치 입력'
                value={searchTerm.location}
                onChange={handleChange}
              />
              <input
                type='text'
                name='hours'
                placeholder='시간 입력'
                value={searchTerm.hours}
                onChange={handleChange}
              />
              <p></p>
              <button onClick={handleSearch}>검색</button>
              <button onClick={goToMainPage}>메인 페이지</button>
              <button onClick={handleLogout}>로그아웃</button>
            </div>
          ) : menupage === 'UserSearch' ? (
            <div className='pagination'>
              <h3>{userData}님의 페이지</h3>
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                이전
              </button>
              <span> 페이지 {currentPage} / {totalPages} </span>
              <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                다음
              </button>
              <p></p>
              <input
                type='text'
                name='location'
                placeholder='위치 입력'
                value={searchTerm.location}
                onChange={handleChange}
              />
              <input
                type='text'
                name='hours'
                placeholder='시간 입력'
                value={searchTerm.hours}
                onChange={handleChange}
              />
              <p></p>
              <button onClick={handleUserSearch}>검색</button>
              <button onClick={goToMainPageUser}>메인 페이지</button>
              <button onClick={handleLogout}>로그아웃</button>
            </div>
          ) : menupage === 'Author' ? (
            <div className='form'>
              <h4>회원가입</h4>
              <h5>로그인 정보</h5>
              <input type='text' name='username' placeholder='아이디' onChange={event => setIsId(event.target.value)}></input>
              <input type='password' name='psw' placeholder='비밀번호' onChange={event => setIsPsw(event.target.value)}></input>
              <h5>회원 정보</h5>
              <input type='text' name='realname' placeholder='이름' onChange={event => setIsName(event.target.value)}></input>
              <input type='text' name='전화번호' placeholder='전화번호' onChange={event => setIsNumber(event.target.value)}></input>
              <input type='text' name='차량번호' placeholder='차량번호' onChange={event => setIsCarNum(event.target.value)}></input>
              <p>
                <input type='submit' value='등록' onClick={handleAuthorAdd}></input>
              </p>
            </div>
          ) : null}
        </div>
      </div>
      <div className='main'>
        {mainpage === 'Public' ? (
          <div className='pubilcbox'>
            <h1 style={{ 'backgroundColor': 'rgba(255,255,255,0.5)', 'margin': '0px' }}>한국 불법 주정차 알림 페이지에 오신 것을 환영합니다!</h1>
            <h3 style={{ 'backgroundColor': 'rgba(255,255,255,0.5)', 'margin': '0px' }}>로그인을 통해 개인 정보에 접근하실 수 있습니다.</h3>
          </div>
        ) : mainpage === 'Admin' ? (
          currentCars.map((car, index) => (
            <div key={index} className='basebox'>
              <div className='imageabox'>
                <img alt={`car${index}`} src={car.carpicture}></img>
              </div>
              <div className='infobox'>
                {editingIndex === index ? (
                  <>
                    <p>차량 번호: <input
                      type='text'
                      name='carnum'
                      value={editedCarData.carnum}
                      onChange={handleInputChange}
                    /></p>
                    <p>위치: <input
                      type='text'
                      name='location'
                      value={editedCarData.location}
                      onChange={handleInputChange}
                    /></p>
                    <p>시간:<input
                      type='text'
                      name='hours'
                      value={editedCarData.hours}
                      onChange={handleInputChange}
                    /></p>
                  </>
                ) : (
                  <>
                    <p>차량 넘버: {car.carnum}</p>
                    <p style={{color:'blue'}} onClick={() => handleLocationValues(car.location)}>위치: {car.location}</p>
                    <p>시간: {car.hours}</p>
                  </>
                )}
              </div>
              <div className='adminButtonbox'>
                {editingIndex === index ? (
                  <button onClick={() => handleSaveClick(car.carnum)}>저장</button>
                ) : (
                  <button onClick={() => handleEditClick(index, car)}>수정</button>
                )}
                <button onClick={() => handleDeleteClick(car.carnum, car.location, car.hours)}>삭제</button>
              </div>
            </div>
          ))
        ) : mainpage === 'User' ? (
          currentCars.map((car, index) => (
            <div key={index} className='basebox'>
              <div className='imageabox'>
                <img alt={`car${index}`} src={car.carpicture}></img>
              </div>
              <div className='infobox'>
                <p>차량 넘버: {car.carnum}</p>
                <p style={{color:'blue'}} onClick={() => handleLocationValues(car.location)}>위치: {car.location}</p>
                <p>시간: {car.hours}</p>
              </div>
            </div>
          ))
        ) : mainpage === 'Search' ? (
          <>
            {results.map((car, index) => (
              <div key={index} className='basebox'>
                <div className='imageabox'>
                  <img alt={`car${index}`} src={car.carpicture}></img>
                </div>
                <div className='infobox'>
                  <p>차량 넘버: {car.carnum}</p>
                  <p style={{color:'blue'}} onClick={() => handleLocationValues(car.location)}>위치: {car.location}</p>
                  <p>시간: {car.hours}</p>
                </div>
              </div>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default App;